import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../css/layout.css";
import "../css/base.css";
import "../css/skeleton.css";
import "./eventMainPage.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import HorizontalLine from "./component/horizontalLine";
import locationIcon from "../images/location.svg";
import airTemp from "../images/temp.svg";
import waterTemp from "../images/temp_2.svg";
import airport from "../images/airport.svg";
import test from "../images/image.png";
import arrowRight from "../images/smallArrowRight.svg";
import arrowRightOrange from "../images/arrowRightOrange.svg";
import RegistrationComponent from "./component/registrationComponent";
import CourseComponent from "./component/courseComponent";
import ContactSection from "./component/contactSection";
import AthleteSection from "./component/athleteSection";
import PartnersSection from "./component/partnersSection";
import getEventById from "../api/events/getEventById";
import { InfinitySpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import CountrySelectorDialog from "./component/countrySelectorDialog";
import { getAnalytics, logEvent } from "firebase/analytics";
import GalleryComponent from "./component/GalleryComponent";


function EventMainPage() {
  const [viewClicked, setViewClicked] = useState(1);
  const [scrolling, setScrolling] = useState(false);
  const [eventData, setEventData] = useState();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirm = (country) => {
    console.log('Selected country:', country);
    handleCloseModal();
  };

  const navigate = useNavigate();

  const parts = window.location.href.split("/");
  const id = parts[parts.length - 1];

  useEffect(() => {
    getEventById(id).then((event) => {
      console.log(event);
      const analytics = getAnalytics();
      logEvent(analytics, `Event_screen_${event.name}`)

      setEventData(event);
      const handleScroll = () => {
        if (window.scrollY > 0) {
          setScrolling(true);
        } else {
          setScrolling(false);
        }
      };
      window.scrollTo({
        top: 0,
      });
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    });
  }, []);

  const renderView = () => {
    console.log(viewClicked);
    switch (viewClicked) {
      case 1:
        return (
          <RegistrationComponent
            topTable={eventData.registration.raceOptionsUrl}
            includesArray={eventData.registration.registrationIncludes}
            pdf={eventData.rulesAndRegulationsUrl}
            id={id}
            isActive={eventData.isActive}
            activeUrl={eventData.paymentRedirectionUrl}
            isLocal={eventData.isLocal}
          />
        );
      case 2:
        return (
          <CourseComponent
            courseInfo={eventData.course}
            pdf={eventData.rulesAndRegulationsUrl}
          />
        );
      case 3:
        return <AthleteSection athleteInfo={eventData.athletesInfo} />;
      case 4:
        return <PartnersSection partners={eventData.partners} />;
      case 5:
        return <ContactSection />;
      case 6:
        return eventData.venueGallery && eventData.venueGallery.length > 0 ? <GalleryComponent images={eventData.venueGallery } text={eventData.renderText} /> : <div/>; 
      default:
        return <div>Default View</div>;
    }
  };

  return (
    <>
      <div
        id="menu-wrap"
        className={`menu-back cbp-af-header ${
          scrolling ? "blue-background" : ""
        }`}
      >
        <div className="container-appbar">
          <div className="twelve columns">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="appbar-left">
                <div
                  onClick={() => {
                    navigate("/");
                  }}
                  className="logo"
                >
                  {" "}
                  <a href="/"></a>
                </div>
                <ul className="slimmenu">
                  <li>
                    <a href="/">Races</a>
                  </li>
                  <li>
                    <a href="/">Why Aquaman</a>
                  </li>
                  <li>
                    <a href="/">Programs</a>
                  </li>
                  <li>
                    <a href="/">Shop</a>
                  </li>
                  <li>
                    <a href="/">Connect</a>
                  </li>
                </ul> 
              </div>
            </div>
          </div>
        </div>
      </div>
      {eventData ? (
        <div className="main-container">
          <div className="carousel-container">
            <Carousel
              additionalTransfrom={0}
              arrows={false}
              autoPlaySpeed={3000}
              centerMode={false}
              className="carousel-main"
              containerClass="container"
              dotListClass=""
              draggable={false}
              focusOnSelect={false}
              infinite
              itemClass=""
              keyBoardControl
              minimumTouchDrag={80}
              pauseOnHover
              renderArrowsWhenDisabled={false}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 1024,
                  },
                  items: 1,
                },
                mobile: {
                  breakpoint: {
                    max: 464,
                    min: 0,
                  },
                  items: 1,
                },
                tablet: {
                  breakpoint: {
                    max: 1024,
                    min: 464,
                  },
                  items: 1,
                },
              }}
              rewind={true}
              rewindWithAnimation={true}
              rtl={false}
              shouldResetAutoplay
              showDots={false}
              sliderClass=""
              slidesToSlide={1}
              swipeable
            >
              {eventData &&
                eventData?.coverUrls.map((url, index) => (
                  <img
                    key={index}
                    src={url}
                    style={
                      window.innerWidth > 700
                        ? {
                            display: "block",
                            height: "100%",
                            margin: "auto",
                            width: "100%",
                            objectFit: "cover",
                          }
                        : {
                            display: "block",
                            height: "100%",
                            margin: "auto",
                            objectFit: "cover",
                          }
                    }
                  />
                ))}
            </Carousel>
            <div className="carousel-text">
              <h3>{eventData.name}</h3>
              <p>{eventData.location}</p>
            </div>
            <div className="carousel-date">{eventData.dateString}</div>
          </div>

          <div className="weather-info">
            {window.innerWidth > 700 && (
              <div className="language-selector">
                <select
                  style={{ padding: "10px 12px", appearance: "none" }}
                  value={""}
                >
                  <option value="">Select Language</option>
                  <option value="English">English</option>
                </select>
              </div>
            )}
            <div className="info">
              <div className="info-section">
                <img className="icon" src={locationIcon} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    lineHeight: "16px",
                  }}
                >
                  <span className="label">SWIM</span>
                  <span className="value">{eventData.swimLabel}</span>
                </div>
              </div>
              <div className="info-section">
                <img className="icon" src={airTemp} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    lineHeight: "16px",
                  }}
                >
                  <span className="label">AVG. AIR TEMP</span>
                  <span className="value">{eventData.averageTemperature}</span>
                </div>
              </div>
              <div className="info-section">
                <img className="icon" src={waterTemp} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    lineHeight: "16px",
                  }}
                >
                  <span className="label">AVG. WATER TEMP</span>
                  <span className="value">
                    {eventData.averageWaterTemperature}
                  </span>
                </div>
              </div>
              <div className="info-section">
                <img className="icon" src={airport} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    lineHeight: "16px",
                  }}
                >
                  <span className="label">AIRPORT</span>
                  <span className="value">{eventData.airport}</span>
                </div>
              </div>
            </div>

            {window.innerWidth > 700 &&
            <>
            {eventData.isActive ? 
              <button className="registration" onClick={() => {
                handleOpenModal()
                }}>
                Register Now
              </button> : <button className="registration" onClick={() => {
               
                }}>
                Registration Opening Soon
              </button>}
            </> 
            }
          </div>
          <HorizontalLine color={"#D69B29"} thickness={"1px"} />
          <br />

          {window.innerWidth > 700 ? (
            <div className="split-container">
              <div className="left-side">
               {eventData.renderText ? <div style={{display: 'flex', flexDirection:'column', alignItems: 'center', backgroundColor: '#F4F7FF', marginBottom: '2em', padding:'1em', borderRadius: '6px'}}> 
                  <p style={{textAlign: 'center', fontSize: '20px', fontWeight: 'bold', color: '#D69B29', marginBottom: '0.5em'}}> Find Your Way </p>
                  <p style={{textAlign: 'center', fontSize: '12px', fontWeight: 'normal', color: '#112348',lineHeight: "18px"}}> Check out the exact race location on Google Maps and plan your trip! </p>
                  <button className="registration" onClick={() => {
                     window.open(eventData.renderText, "_blank");
                  }}>View Location on Maps</button>
                  </div> : <></>}
                <div
                  className={
                    viewClicked == 1
                      ? "text-with-arrow clicked"
                      : "text-with-arrow"
                  }
                  onClick={() => {
                    setViewClicked(1);
                  }}
                >
                  Registration
                  {viewClicked == 1 ? (
                    <img src={arrowRightOrange}></img>
                  ) : (
                    <img
                      src={arrowRight}
                      style={{ marginRight: "1.2em" }}
                    ></img>
                  )}
                </div>
                <div
                  className={
                    viewClicked == 2
                      ? "text-with-arrow clicked"
                      : "text-with-arrow"
                  }
                  onClick={() => {
                    setViewClicked(2);
                  }}
                >
                  Course
                  {viewClicked == 2 ? (
                    <img src={arrowRightOrange}></img>
                  ) : (
                    <img
                      src={arrowRight}
                      style={{ marginRight: "1.2em" }}
                    ></img>
                  )}
                </div>
                <div
                  className={
                    viewClicked == 3
                      ? "text-with-arrow clicked"
                      : "text-with-arrow"
                  }
                  onClick={() => {
                    setViewClicked(3);
                  }}
                >
                  Event Schedule
                  {viewClicked == 3 ? (
                    <img src={arrowRightOrange}></img>
                  ) : (
                    <img
                      src={arrowRight}
                      style={{ marginRight: "1.2em" }}
                    ></img>
                  )}
                </div>
               {eventData.venueGallery && eventData.venueGallery.length > 0 && <div
                  className={
                    viewClicked == 6
                      ? "text-with-arrow clicked"
                      : "text-with-arrow"
                  }
                  onClick={() => {
                    setViewClicked(6);
                  }}
                >
                  Venue Gallery
                  {viewClicked == 5 ? (
                    <img src={arrowRightOrange}></img>
                  ) : (
                    <img
                      src={arrowRight}
                      style={{ marginRight: "1.2em" }}
                    ></img>
                  )}
                </div>}
                <div
                  className={
                    viewClicked == 4
                      ? "text-with-arrow clicked"
                      : "text-with-arrow"
                  }
                  onClick={() => {
                    setViewClicked(4);
                  }}
                >
                  Partners
                  {viewClicked == 4 ? (
                    <img src={arrowRightOrange}></img>
                  ) : (
                    <img
                      src={arrowRight}
                      style={{ marginRight: "1.2em" }}
                    ></img>
                  )}
                </div>
                <div
                  className={
                    viewClicked == 5
                      ? "text-with-arrow clicked"
                      : "text-with-arrow"
                  }
                  onClick={() => {
                    setViewClicked(5);
                  }}
                >
                  Connect
                  {viewClicked == 5 ? (
                    <img src={arrowRightOrange}></img>
                  ) : (
                    <img
                      src={arrowRight}
                      style={{ marginRight: "1.2em" }}
                    ></img>
                  )}
                </div>
              </div>
              <div className="right-side">{renderView()}</div>
            </div>
          ) : (
            <div className="split-container-mobile">
              <div className="language-selector MobileCenterEvent">
              {eventData.renderText? <div style={{display: 'flex', flexDirection:'column', alignItems: 'center', backgroundColor: '#F4F7FF', marginBottom: '2em', padding:'1em', borderRadius: '6px'}}> 
                  <p style={{textAlign: 'center', fontSize: '16px', fontWeight: 'bold', color: '#D69B29' }}> Find Your Way </p>
                  <p style={{textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: '#112348',lineHeight: "14px"}}> Check out the exact race location on Google Maps and plan your trip! </p>
                  <button className="registration" onClick={() => {
                     window.open(eventData.renderText, "_blank");
                  }}>View Location on Maps</button>
                  </div> : <></>}
                <select
                  value={viewClicked}
                  onChange={(event) => {
                    const selectedValue = parseInt(event.target.value, 10);
                    setViewClicked(selectedValue);
                  }}
                >
                  <option value="1">Registration</option>
                  <option value="2">Course</option>
                  <option value="3"> Event Schedule</option>
                 {eventData.venueGallery && eventData.venueGallery.length > 0 && <option value="6">Venue Gallery</option>}
                  <option value="4">Partners</option>
                  <option value="5">Connect</option>
                </select>
              </div>
              <div>{renderView()}</div>
            </div>
          )}
          {window.innerWidth < 700 && eventData.isActive && (
            <div className="floating-button">
              
         
              <button className="registration" onClick={() => {
                handleOpenModal()
                }}>
                Register Now
              </button> 
              
             
            </div>
          )}
        {isModalOpen && <CountrySelectorDialog
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        onConfirm={handleConfirm}
        id={id}
        isLocal={eventData.isLocal} 
        activeUrl={eventData.paymentRedirectionUrl}
      />} 
        </div>
      ) : (
        <InfinitySpin
          visible={true}
          width="200"
          color="#4fa94d"
          ariaLabel="infinity-spin-loading"
        />
      )}
    </>
  );
}

export default EventMainPage;
