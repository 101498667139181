import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./css/layout.css";
import "./css/base.css";
import "./css/skeleton.css";
import Gallery from "./components/Slideshow";
import getAllEvents from "./api/events/getAllEvents";
import Partners from "./components/partners";
import contactUs from "./api/contactus/contactUs";
import { getAnalytics, logEvent } from "firebase/analytics";
import { slide as Menu } from "react-burger-menu";

function Dashboard() {
  const navigate = useNavigate();
  const menuRef = useRef();
  const navbarRef = useRef();
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const [scrollTrigger, setScrollTrigger] = useState("");
  const [events, setEvents] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    info: "",
  });
  const [racesOpen, setRacesOpen] = useState(false);

  const analytics = getAnalytics();
  logEvent(analytics, "screen_view", {
    firebase_screen: "DASHBOARD",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    try {
      await contactUs(formData).then((_) => {
        alert("Thank you for contacting us!");
      }); // Call postEventData function with formData
      setFormData({
        name: "",
        email: "",
        info: "",
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Error submitting form. Please try again later.");
    }
  };

  useEffect(() => {
    const handleResize = () => setScreenHeight(window.innerHeight);
    const handleResizew = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    window.addEventListener("resize", handleResizew);

    const handleResizeMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Check for mobile screen size
    };

    handleResizeMobile(); // Initialize on component mount
    window.addEventListener("resize", handleResizeMobile);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResizew);
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("resize", handleResizeMobile);
    };
  }, []);

  useEffect(() => {
    getAllEvents().then((events) => {
      setEvents(events);
      const handleScroll = () => {
        if (window.scrollY > 0) {
          setScrolling(true);
        } else {
          setScrolling(false);
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    });
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const element = document.getElementById(scrollTrigger);
    const appBarHeight = document.getElementById("menu-wrap").offsetHeight;

    if (scrollTrigger == "top") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    if (element) {
      const offset = element.offsetTop - appBarHeight;
      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
    setScrollTrigger("");
  }, [scrollTrigger]);

  const menuItems = (
    <ul className="nav-menu">
      <li className={`nav-item ${racesOpen ? "open" : ""}`}>
        <a
          className="nav-link"
          onClick={() => setRacesOpen((prev) => !prev)}
        >
          Races ▼
        </a>
        <div className="dropdown-container">
          <button className="dropdown-btn" onClick={() =>{ setScrollTrigger("races"); setMenuOpen(false)}}>
            Upcoming Races ▶
          </button>
          <button className="dropdown-btn" disabled onClick={() => navigate('/event/previous')}>
            Previous Races ▶
          </button>
        </div>
      </li>
      <li className="nav-item">
        <a className="nav-link" onClick={() => { setScrollTrigger("why"); setMenuOpen(false); }}>
          Why Aquaman
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" onClick={() => { setScrollTrigger("programs"); setMenuOpen(false); }}>
          Programs
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" onClick={() => { setScrollTrigger("store"); setMenuOpen(false); }}>
          Shop
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" onClick={() => { setScrollTrigger("blog"); setMenuOpen(false); }}>
          Connect
        </a>
      </li>
    </ul>
  );
  
  

  const handleMenuStateChange = (state) => {
    setMenuOpen(state.isOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  const handleScroll = () => {
    if (navbarRef.current) {
      const navbarOffsetTop = navbarRef.current.offsetTop;
      setScrolling(window.scrollY >= navbarOffsetTop);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      {screenWidth > 768 && (
        <div className="top-flair">
          <div className="flair-cont">
            <p className="flair-content">
              <strong style={{ letterSpacing: "0.1em" }}>
                Be Among the First to Preview the 2025 Race Lineup!{" "}
              </strong>
            </p>
            <button
              onClick={() => setScrollTrigger("races")}
              className="flair-btn"
            >
              Discover Now
            </button>
          </div>
        </div>
      )}
      <div
        id="menu-wrap"
        ref={navbarRef}
        className={`menu-back ${
          scrolling ? "fixed-navbar blue-background" : "cbp-af-header"
        }`}
      >
        {screenWidth < 768 ? (
          <div>
            <div
              className="logo"
              onClick={() => {
                setScrollTrigger("top");
              }}
            >
              <a href="home"></a>
            </div>
            <Menu right isOpen={menuOpen} onStateChange={handleMenuStateChange}>
              {menuItems}
            </Menu>
          </div>
        ) : (
          <div className="container-appbar">
            <div className="twelve columns" style={{ marginTop: "1.5em" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="appbar-left">
                  <div
                    className="logo"
                    onClick={() => {
                      setScrollTrigger("top");
                    }}
                  >
                    <a href="home"></a>
                  </div>
                  <ul className="slimmenu">
                    <li
                      className="races-menu"
                      onMouseEnter={() => !isMobile && setRacesOpen(true)}
                      onMouseLeave={() => !isMobile && setRacesOpen(false)}
                    >
                      <a
                        onClick={() => {
                          if (isMobile) {
                            setRacesOpen((prev) => !prev);
                          }
                        }}
                      >
                        Races ▼
                      </a>
                      {racesOpen && (
                        <div className="dropdownMain">
                          <button
                            className="race-btn"
                            onClick={() => setScrollTrigger("races")}
                          >
                            Upcoming Races{" "}
                            <span className="right-arrow">▶</span>
                          </button>
                          <button className="race-btn" disabled
                          onClick={() => {navigate('/event/previous')}}>
                            Previous Races{" "}
                            <span className="right-arrow">▶</span>
                          </button>
                        </div>
                      )}
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          setScrollTrigger("why");
                        }}
                      >
                        Why Aquaman
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          setScrollTrigger("programs");
                        }}
                      >
                        Programs
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          setScrollTrigger("store");
                        }}
                      >
                        Shop
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          setScrollTrigger("blog");
                        }}
                      >
                        Connect
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="main-button">Become a Partner</div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="cd-main">
        <div className="section" id="home">
          <div className="hero-top">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>
                <p className="container-top-main" style={{ fontSize: "65px" }}>
                  AQUAMAN OPEN WATER SWIMMING
                </p>
              </div>
            </div>
          </div>
          <div
            style={{
              height: "630px",
              width: "100%",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <iframe
              src="https://player.vimeo.com/video/1031942625?autoplay=1&loop=1&muted=1&controls=0"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                // Makes sure the iframe is wide enough
                height: "100%", // Ensures it is tall enough
                border: "none",
                transform: `scale(${Math.max(
                  (630 * 2.73) / screenWidth,
                  (screenHeight * screenWidth) / (1720 * 630)
                )})`,
              }}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              title="Fullscreen Video Player"
            ></iframe>
          </div>
        </div>
      </div>
      <div className="dashboard-sponsors">
        <p
          className="smaller"
          style={{
            color: "white",
            textAlign: "center",
            fontFamily: "Poppins",
            fontStyle: "italic",
            fontSize: "16px",
            fontWeight: 900,
          }}
        >
          POWERED BY BOOST
        </p>
        <p
          className="smaller"
          style={{
            color: "white",
            textAlign: "center",
            fontFamily: "Poppins",
            fontWeight: 600,
            fontSize: "16px",
            marginTop: "4px",
          }}
        >
          join the{" "}
          <span
            className="smaller"
            style={{
              color: "#D69B29",
              textAlign: "center",
              fontFamily: "Poppins",
              fontWeight: 600,
              fontSize: "20px",
              marginTop: "4px",
            }}
          >
            50+
          </span>{" "}
          Partners that made Aquaman happen
        </p>
        <br></br>
        <Partners />
      </div>
      <div id="races">
        <div className="section" style={{}}>
          <div className="container">
            <div className="twelve columns">
              <div
                className="header-text main-pages"
                style={{ marginTop: "50px" }}
              >
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: 900,
                    fontSize: "54px",
                    color: "#112348",
                    lineHeight: "1.1",
                  }}
                >
                  2025 Races
                </span>
                <div
                  style={{
                    fontWeight: 400,
                    fontSize: "20px",
                    fontFamily: "Poppins",
                    color: "#112348",
                    marginTop: "24px",
                    textAlign: "center",
                    lineHeight: "1.2", // Adjust this value as needed
                  }}
                >
                  Discover Your Dream Swim Race and dive into Aquaman's
                  Selection Below!
                </div>
              </div>
            </div>
          </div>
        </div>

        {window.innerWidth < 1200 ? (
          <div>
            {events.map((event, index) => (
              <img
                key={index}
                src={event.imageUrl}
                alt={`Image ${index + 1}`}
                style={{ width: "100%", cursor: "pointer" }}
                onClick={(_) => {
                  // if(event.isActive){
                  navigate(`/event/${event._id}`);
                  // }
                }}
              />
            ))}
          </div>
        ) : (
          <Gallery
            imageUrls={events.map((event) => event.imageUrl)}
            ids={events.map((event) => event._id)}
            isActives={events.map((event) => true)}
          />
        )}
      </div>
      {/* <div className="slider-container">
        <div className="arrow arrow-left" onClick={() => {}}>&#10094;</div>
        <div className="slider">
          <div className="slide"><img src="https://domf5oio6qrcr.cloudfront.net/medialibrary/9833/GettyImages-526245433.jpg" alt="Image 1" /></div>
          <div className="slide"><img src="https://domf5oio6qrcr.cloudfront.net/medialibrary/9833/GettyImages-526245433.jpg" alt="Image 2" /></div>
          <div className="slide"><img src="https://domf5oio6qrcr.cloudfront.net/medialibrary/9833/GettyImages-526245433.jpg" alt="Image 3" /></div>
          <div className="slide"><img src="https://domf5oio6qrcr.cloudfront.net/medialibrary/9833/GettyImages-526245433.jpg" alt="Image 3" /></div>
          <div className="slide"><img src="https://domf5oio6qrcr.cloudfront.net/medialibrary/9833/GettyImages-526245433.jpg" alt="Image 3" /></div>
        </div>
        <div className="arrow arrow-right" onClick={() => {}}>&#10095;</div>
      </div> */}

      <div className="clear"></div>

      <div id="why">
        <div className="whyaquaman">
          <div className="whyaquaman-left">
            <img
              src="https://makanakboost.s3.eu-central-1.amazonaws.com/aquaman/backgrounds/AM_Homepage_About-05.png"
              class="whyaquaman-left-image"
              alt="Your Image"
            />
          </div>

          <div className="whyaquaman-right">
            <p
              style={{
                fontFamily: "Poppins",
                fontWeight: 700,
                color: "#D69B29",
                fontSize: "10px",
              }}
            >
              ABOUT US
            </p>
            <br />
            <p
              style={{
                fontFamily: "Poppins",
                fontWeight: 700,
                color: "#112348",
                fontSize: "40px",
              }}
            >
              Why Aquaman
            </p>
            <br />
            <p
              style={{
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#112348",
                fontSize: "16px",
              }}
            >
              At Aquaman, we're your go to for open water swimming competitions.
              Partnered with Boost for top-notch event hosting, we offer six
              races suited for all levels: Aquaman 10km, Aquaman 5.0 5km,
              Aquaman 2.0 2km, Aquakids 500m, Aquateams 3X500m, and Aquafins
              1km. Dive into the excitement, whether you're a seasoned pro or
              just starting out. Start your swimming journey with us today!
            </p>
          </div>
        </div>

        <div
          class="section padding-top-bottom-smaller"
          style={{ backgroundColor: "#112348" }}
        >
          <div class="container flex-column" style={{ display: "flex" }}>
            <div class="twelve columns">
              <div class="counter-wrap">
                <div class="counter-numb">
                  <em
                    class="counter-facts"
                    style={{ color: "#00EEFF", fontWeight: "900" }}
                  >
                    60+
                  </em>
                </div>
                <p style={{ fontSize: "12px", letterSpacing: "1px" }}>
                  Countries participate in our event{" "}
                  {window.innerWidth > 780 && <br />} each year
                </p>
              </div>
            </div>
            <div class="twelve columns">
              <div class="counter-wrap">
                <div class="counter-numb">
                  <em
                    class="counter-facts"
                    style={{ color: "#00EEFF", fontWeight: "bold" }}
                  >
                    25,000+
                  </em>
                </div>
                <p style={{ fontSize: "12px", letterSpacing: "1px" }}>
                  Swimmers participate in events and our swim in our academies
                </p>
              </div>
            </div>
            <div class="twelve columns">
              <div class="counter-wrap">
                <div class="counter-numb">
                  <em
                    class="counter-facts"
                    style={{ color: "#00EEFF", fontWeight: "bold" }}
                  >
                    25+
                  </em>
                </div>
                <p style={{ fontSize: "12px", letterSpacing: "1px" }}>
                  partners in success around {window.innerWidth > 780 && <br />}{" "}
                  the world
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="clear"></div>
      <div id="programs">
        {/* <div class="section padding-top-bottom grey-background">
          <div class="container">
            <div class="twelve columns">
              <div class="header-text main-pages">
                <span style={{ color: "#112348" }}>Our Programs</span>
                <br />
                <br />
                <h4>
                  Explore our programs: become a Certified Aquaman Coach, train
                  on-
                </h4>
                <h4>ground, or access online race training.</h4>
              </div>
            </div>
            <div
              class="four columns"
              data-scroll-reveal="enter bottom move 100px over 0.4s after 0.2s"
            >
              <div class="team-box">
                <img
                  src={
                    "https://makanakboost.s3.eu-central-1.amazonaws.com/aquaman/programs/AM_Homepage-10.png"
                  }
                />
              </div>
            </div>
            <div class="four columns">
              <div class="team-box">
                <img
                  src={
                    "https://makanakboost.s3.eu-central-1.amazonaws.com/aquaman/programs/AM_Homepage-11.png"
                  }
                />
              </div>
            </div>
            <div class="four columns">
              <div class="team-box">
                <img
                  src="https://makanakboost.s3.eu-central-1.amazonaws.com/aquaman/programs/AM_Homepage-12.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div> */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "4em 0",
          }}
        >
          <img
            style={{ width: "80vw" }}
            alt="Aquaman Loyalty Program"
            src={
              "https://firebasestorage.googleapis.com/v0/b/aquaman-5b7b3.appspot.com/o/Aquaman%20Loyalty%20Program.svg?alt=media&token=901ec487-662b-44c3-bf89-13669011f832"
            }
          />
        </div>
      </div>
      <div className="clear"></div>
      <div id="store">
        <div class="section">
          <div class="container">
            <div class="twelve columns">
              <div class="header-text main-pages" style={{ marginTop: "50px" }}>
                <span
                  style={{
                    color: "#112348",
                    fontSize: "48px",
                    fontFamily: "Poppins",
                    fontWeight: "900",
                    lineHeight: "1.1",
                  }}
                >
                  Aquaman's Store
                </span>
                <div
                  style={{
                    fontWeight: 400,
                    fontSize: "20px",
                    fontFamily: "Poppins",
                    color: "#112348",
                    marginTop: "32px",
                    textAlign: "center",
                    lineHeight: "1.2", // Adjust this value as needed
                  }}
                >
                  Discover Your Dream Swim Race and dive into Aquaman's
                  Selection Below!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="clear"></div>
      <div>
        <div class="section  padding-top-bottom-smaller">
          {/* <div class="parallax-3"></div> */}
          <div
            class="container"
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "0 32px",
              maxWidth: "100%",
              height: "auto",
            }}
          >
            <img
              style={{ height: "auto", maxWidth: "400px", padding: "0 16px" }}
              src="https://firebasestorage.googleapis.com/v0/b/aquaman-5b7b3.appspot.com/o/Aquaman%20store%2FProduct%201.svg?alt=media&token=5f0a0d48-c797-4f7a-aea1-9d3417542500"
            ></img>

            <img
              className="hide-mobile"
              style={{ height: "auto", maxWidth: "400px", padding: "0 16px" }}
              src="https://firebasestorage.googleapis.com/v0/b/aquaman-5b7b3.appspot.com/o/Aquaman%20store%2FProduct%202.svg?alt=media&token=42c008ca-3a7c-4adc-af82-f192a95cc0f1"
            ></img>
            <img
              className="hide-mobile-two"
              style={{ height: "auto", maxWidth: "400px", padding: "0 16px" }}
              src="https://firebasestorage.googleapis.com/v0/b/aquaman-5b7b3.appspot.com/o/Aquaman%20store%2FProduct%203.svg?alt=media&token=9fb8be28-3d8a-4abd-8c45-c5fb00d8fe79"
            ></img>
          </div>
          <div>
            <div
              data-lang="en"
              style={{
                backgroundColor: "#D69B29",
                fontFamily: "Poppins",
                color: "white",
                fontSize: "16px",
                fontWeight: 600,
                borderRadius: "4px",
                padding: "12px 16px",
                textAlign: "center",
                overflow: "hidden",
                whiteSpace: "nowrap",
                width: "fit-content",
                margin: "16px auto",
              }}
            >
              Shop Now ..Coming soon
            </div>
          </div>
        </div>
      </div>
      <div className="clear"></div>

      <div id="blog" class="contactus-container">
        <span
          style={{
            fontFamily: "Poppins",
            fontWeight: 600,
            color: "#112348",
            fontSize: "36px",
            lineHeight: "1.1",
            textAlign: "center",
          }}
        >
          Get in Touch with Aquaman
        </span>
        <div
          style={{
            fontFamily: "Poppins",
            fontWeight: 400,
            color: "#112348",
            fontSize: "20px",
            marginTop: "32px",
            textAlign: "center",
          }}
        >
          We're here to answer any questions you have.
        </div>
      </div>
      <form
        name="ajax-form"
        id="ajax-form"
        onSubmit={handleSubmit}
        method="post"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div data-scroll-reveal="enter bottom move 100px over 0.4s after 0.2s">
            <label for="name">
              <span class="error" id="err-name">
                please enter name
              </span>
            </label>
            <input
              name="name"
              id="name"
              type="text"
              placeholder="Your Name: *"
              onChange={handleChange}
            />
          </div>
          <div data-scroll-reveal="enter bottom move 100px over 0.4s after 0.2s">
            <label for="email">
              <span class="error" id="err-email">
                please enter e-mail
              </span>
              <span class="error" id="err-emailvld">
                e-mail is not a valid format
              </span>
            </label>
            <input
              name="email"
              id="email"
              type="text"
              placeholder="E-Mail: *"
              onChange={handleChange}
            />
          </div>
        </div>
        <div
          class="twelve columns"
          data-scroll-reveal="enter bottom move 100px over 0.4s after 0.2s"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <label for="info"></label>
            <textarea
              name="info"
              id="info"
              placeholder="Tell Us Everything"
              onChange={handleChange}
            ></textarea>
          </div>
        </div>
        <div
          class="twelve columns"
          data-scroll-reveal="enter bottom move 100px over 0.4s after 0.2s"
        >
          <div>
            <button class="contactus-button" data-lang="en">
              Submit
            </button>
          </div>
        </div>
        <div class="clear"></div>
        <div class="error text-align-center" id="err-form">
          There was a problem validating the form please check!
        </div>
        <div class="error text-align-center" id="err-timedout">
          The connection to the server timed out!
        </div>
        <div class="error" id="err-state"></div>
      </form>

      <div className="clear"></div>
    </div>
  );
}

export default Dashboard;
